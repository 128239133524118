import { ClipboardEvent, forwardRef, ForwardRefRenderFunction, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { Input } from './Input';

type OTPInputProps = {
  count?: number;
  allowRegex?: RegExp;

  // Create a seperator space after every index this function returns true for
  groupingFn?: (index: number) => boolean;

  onComplete?: (pin: string) => void;
  disabled?: boolean;
  size?: 'small' | 'normal';
};

type OTPHandle = {
  clearOtp: () => void;
};

const DefaultGroupingFn = (index: number) => index === 2;

const OTPInput: ForwardRefRenderFunction<OTPHandle, OTPInputProps> = (props, ref) => {
  const { count = 6, allowRegex = /^[0-9]{1}$/, groupingFn = DefaultGroupingFn, onComplete, disabled, size = 'normal' } = props;

  const initialPin = useMemo(() => new Array(count).fill(''), [count]);
  const [pinArr, setPinArr] = useState(initialPin);
  const pin = useMemo(() => pinArr.join(''), [pinArr]);

  const inputRefs = useRef<HTMLInputElement[]>([]);

  useImperativeHandle(ref, () => ({
    clearOtp: () => {
      setPinArr(initialPin);
      new Promise<void>((resolve) => resolve()).then(() => {
        inputRefs.current[0].focus();
      });
    },
  }));

  const nextStep = useCallback(
    (index: number, pin: string) => {
      // Call complete callback, or go onto next input
      if (index === count - 1) {
        onComplete && onComplete(pin);
      } else {
        // wait for next input to enable before calling focus on it
        new Promise<void>((resolve) => resolve()).then(() => {
          inputRefs.current[index + 1].focus();
        });
      }
    },
    [count, onComplete],
  );

  const onChange = useCallback(
    (index: number, event: KeyboardEvent) => {
      if (event.key === 'Tab') {
        return;
      }

      if (['Backspace', 'Delete'].includes(event.key)) {
        setPinArr((prev) => {
          const newPin = [...prev];
          // Delete previous entry if the current one is empty (we pressed escape/delete with intention to delete that)
          const deletePrev = newPin[index] === '';

          // Clear all digits after the current one as well
          for (let i = 0; i < prev.length; i++) {
            if (deletePrev && i === index - 1) {
              newPin[i] = '';
            } else if (i >= index) {
              newPin[i] = '';
            }
          }

          return newPin;
        });

        // Focus the previous one if it exists
        inputRefs.current[index - 1]?.focus();
        return;
      }

      // Move Right
      if (event.key === 'ArrowRight') {
        inputRefs.current[index + 1]?.focus();
        return;
      }

      // Move Left
      if (event.key === 'ArrowLeft') {
        inputRefs.current[index - 1]?.focus();
        return;
      }

      const text = event.key;

      // Doesn't match regex, so ignore input
      if (!allowRegex.test(text)) {
        return;
      }

      setPinArr((prev) => {
        const newPin = [...prev];
        for (let i = 0; i < prev.length; i++) {
          // set the input at index to what we entered
          if (i === index) {
            newPin[i] = text;
          }
          // clear everything after it
          else if (i > index) {
            newPin[i] = '';
          }
        }

        return newPin;
      });

      nextStep(index, pin + text); // pin is still outdated at this stage
    },
    [allowRegex, nextStep, pin],
  );

  const onPaste = useCallback(
    (index: number, e: ClipboardEvent) => {
      const pasted = e.clipboardData?.getData('text').split('') ?? [];
      if (pasted.length > 0 && pasted.length <= count && pasted.map((x) => allowRegex.test(x)).every((x) => x)) {
        const newPin = [...pinArr];
        let pastedIndex = 0;
        for (let i = index; i < newPin.length; i++) {
          newPin[i] = pasted[pastedIndex++] || '';
        }

        setPinArr(newPin);
        nextStep(index + pasted.length - 1, newPin.join(''));
      }
    },
    [allowRegex, count, nextStep, pinArr],
  );

  return (
    <div className={`flex justify-center ${size === 'small' ? 'gap-1' : 'my-8 gap-6'}`} data-cy="otp-input">
      {initialPin.map((_, i) => (
        <Input
          key={i}
          className={`text-dpm-25 w-10 text-center font-medium`}
          wrapperClassName={`${groupingFn(i) ? (size === 'small' ? 'mr-2' : 'mr-6') : ''} ${
            size === 'small' ? 'transform scale-75 mx-auto' : ''
          } w-12`}
          autoFocus={i === 0}
          innerRef={(el) => (inputRefs.current[i] = el as HTMLInputElement)}
          onKeyPress={(e) => onChange(i, e)}
          disabled={disabled || (i !== 0 && i > pin.length)}
          onChange={() => ({})} // React complains if we use value, but not onChange
          value={pinArr[i]}
          data-cy={`otp-input-${i + 1}`}
          onPaste={(e) => onPaste(i, e)}
        />
      ))}
    </div>
  );
};

export default forwardRef(OTPInput);
